export interface UserFeaturesListRow {
  feature_name: string;
  quantity: number;
  updated_at: string;
  expires_at?: string;
}

// these are constant part of the product
export type KnownFeatures =
  | 'premium'
  | 'basic'
  | 'midi'
  | 'additional_universes'
  | 'additional_devices'
  | 'live_plot'
  | 'trial_basic'
  | 'subscription.1u'
  | 'subscription.4u'
  | 'subscription.256u'
  | 'subscription.midi'
  | 'subscription.live_plot';

// these can change over time
export type KnownFlags =
  | 'flag_require_auth_after_days'
  | 'flag_organization_account'
  | 'flag_show_debug_menu'
  | 'flag_show_liveplot_debug_menu'
  | 'flag_show_liveplot_3d'
  | 'flag_encoders_tab'
  | 'flag_fixture_view_states';

export type FeatureFlags = KnownFeatures | KnownFlags;

export class UserFeatures {
  user_features_list: UserFeaturesListRow[] = [];
  user_features_map: Record<string, number> = {};

  _expirationDates: number[] = [];

  get earliestUpcomingExpiration(): number | undefined {
    if (!this._expirationDates.length) {
      return undefined;
    }

    return Math.min(...this._expirationDates);
  }

  _updated_at: Date = new Date();
  get updated_at(): string {
    return this._updated_at.toISOString();
  }
  set updated_at(value: Date | string) {
    if (value instanceof Date) {
      this._updated_at = value;
    } else {
      this._updated_at = new Date(value);
    }
    // console.log('set updated_at:', value, this._updated_at);
  }

  static fromList(list: UserFeaturesListRow[]): UserFeatures {
    // debug
    // list = [
    //   { feature_name: 'debug', quantity: 1, updated_at: '1970-01-01T00:00:00Z' },
    //   // { feature_name: 'basic', quantity: 1, updated_at: '1970-01-01T00:00:00Z' },
    //   // { feature_name: 'midi', quantity: 1, updated_at: '1970-01-01T00:00:00Z' },
    //   // { feature_name: 'live_plot', quantity: 1, updated_at: '1970-01-01T00:00:00Z' },
    //   // { feature_name: 'additional_universes', quantity: 253, updated_at: '1970-01-01T00:00:00Z' },
    //   // { feature_name: 'premium', quantity: 1, updated_at: '1970-01-01T00:00:00Z' },
    // ];
    if (!list?.length) {
      return NO_USER_FEATURES;
    }

    const instance = new UserFeatures();
    instance.user_features_list = list;

    let updated_at: Date | undefined;
    list.forEach(row => {
      if (!instance.user_features_map[row.feature_name]) {
        // initialize the feature map with 0
        instance.user_features_map[row.feature_name] = 0;
      }

      if (row.expires_at) {
        instance._expirationDates.push(new Date(row.expires_at).getTime());
      }

      const hasExpired = row.expires_at && new Date(row.expires_at) < new Date();
      if (!hasExpired) {
        // only add the feature if it has not expired
        instance.user_features_map[row.feature_name] += row.quantity;
      }

      if (!updated_at || new Date(row.updated_at) > updated_at) {
        updated_at = new Date(row.updated_at);
      }
    });
    instance.updated_at = updated_at ?? new Date('1970-01-01T00:00:00Z');
    // console.info('-'.repeat(80));
    // console.info('UserFeatures.fromList:', instance);
    // console.info('-'.repeat(80));
    // console.info('planName', instance.planName);
    // console.info('isBasicUser', instance.isBasicUser);
    // console.info('isPremiumUser', instance.isPremiumUser);
    // console.info('isFreeUser', instance.isFreeUser);
    // console.info('isOrganization', instance.isOrganization);
    // console.info('hasMIDI', instance.hasMIDI);
    // console.info('hasLivePlot', instance.hasLivePlot);
    // console.info('maxUniverses', instance.maxUniverses);
    // console.info('maxDevices', instance.maxDevices);
    // console.info('-'.repeat(80));
    return instance;
  }

  hasFeature(feature: KnownFeatures): boolean {
    return this.user_features_map[feature] > 0;
  }

  getFeature(feature: KnownFeatures): number {
    return this.user_features_map[feature] ?? 0;
  }

  /**
   *
   * @param flag feature flags
   * @returns
   */
  hasFlag(flag: FeatureFlags): boolean {
    return this.user_features_map[flag] > 0;
  }

  getFlag(flag: FeatureFlags): number {
    return this.user_features_map[flag] ?? 0;
  }

  get planName(): string {
    const withMidi = this.hasMIDI ? ' + MIDI' : '';
    const withLivePlot = this.hasLivePlot ? ' + LivePlot' : '';

    if (this.hasSubscription) {
      return `Subscription (${this.maxUniverses}U)` + withMidi + withLivePlot;
    }

    if (this.isPremiumUser) {
      return 'Premium' + withMidi + withLivePlot;
    }

    if (this.isBasicUser) {
      return 'Basic' + withMidi + withLivePlot;
    }

    // can Free have MIDI?
    return 'Free';
  }

  get isBasicUser(): boolean {
    return !this.isPremiumUser && this.hasFeature('basic');
  }

  get isTrialBasicUser(): boolean {
    return this.hasFeature('trial_basic') && this.isFreeUser;
  }

  get isPremiumUser(): boolean {
    return this.hasFeature('premium');
  }

  get isFreeUser(): boolean {
    return !this.isPremiumUser && !this.isBasicUser && !this.hasSubscription;
  }

  get isOrganization(): boolean {
    return this.hasFlag('flag_organization_account');
  }

  get hasSubscription(): boolean {
    return (
      this.hasFeature('subscription.1u') ||
      this.hasFeature('subscription.4u') ||
      this.hasFeature('subscription.256u')
    );
  }

  get hasMIDI(): boolean {
    return this.hasFeature('midi') || this.hasFeature('subscription.midi');
  }

  get hasLivePlot(): boolean {
    return this.hasFeature('live_plot') || this.hasFeature('subscription.live_plot');
  }

  get maxUniverses(): number {
    if (this.hasFeature('subscription.1u')) {
      return 1;
    }
    if (this.hasFeature('subscription.4u')) {
      return 4;
    }
    if (this.hasFeature('subscription.256u')) {
      return 256;
    }

    if (this.isPremiumUser) {
      return 256;
    }
    if (this.isBasicUser) {
      return 1 + this.getFeature('additional_universes');
    }
    return 0;
  }

  get maxDevices(): number {
    if (this.hasSubscription) {
      return 1;
    }
    return 2 + this.getFeature('additional_devices');
  }

  get hasEverything(): boolean {
    return this.hasLivePlot && this.hasMIDI && (this.isPremiumUser || this.maxUniverses === 256);
  }

  toString() {
    // ${JSON.stringify(this.user_features_map, null, 2)},
    return `UserFeatures<${JSON.stringify(
      {
        updated_at: this.updated_at,
        planName: this.planName,
        // isBasicUser: this.isBasicUser,
        // isPremiumUser: this.isPremiumUser,
        // isFreeUser: this.isFreeUser,
        // hasMIDI: this.hasMIDI,
        // hasLivePlot: this.hasLivePlot,
        // maxUniverses: this.maxUniverses,
        // maxDevices: this.maxDevices,
        // isTrialBasicUser: this.isTrialBasicUser,
      },
      null,
      2
    )}>`;
  }

  isOlderThan(other: UserFeatures): boolean {
    // console.log('isOlderThan:', this._updated_at, other._updated_at);
    return !this.isNewerThan(other);
  }

  isNewerThan(other: UserFeatures): boolean {
    return this._updated_at.getTime() > other._updated_at.getTime();
  }
}

// this is a special case where we have no user features
export const NO_USER_FEATURES: UserFeatures = UserFeatures.fromList([
  {
    feature_name: 'NO_USER_FEATURES',
    quantity: 1,
    updated_at: '1970-01-01T00:00:00Z',
  },
]);
