import { Injectable } from '@angular/core';

import { toSignal } from '@angular/core/rxjs-interop';
import { BehaviorSubject } from 'rxjs';

import { SupaUser, UserType } from '../admin/shared/supabase/database-extension.types';
import { SupabaseService } from '../admin/shared/supabase/supabase.service';
import { UserFeatures, UserFeaturesListRow } from './models/UserFeatures';

export type AppUser = SupaUser & { user_type?: UserType };

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly userSubject = new BehaviorSubject<AppUser | null>(null);
  user$ = this.userSubject.asObservable();
  userFeatures$ = new BehaviorSubject<UserFeatures>(UserFeatures.fromList([]));

  userFeatures = toSignal(this.userFeatures$, {
    initialValue: UserFeatures.fromList([]),
  });

  get user(): AppUser | null {
    return this.userSubject.value;
  }
  get isUserRegistered(): boolean {
    return !!this.user?.first_name && !!this.user?.last_name;
  }

  constructor(private readonly supabaseService: SupabaseService) {}

  setUser(user: AppUser | null) {
    this.userSubject.next(user);
    this.fetchUserFeatures();
  }

  async updateLoggedInUser(user: AppUser) {
    const { error } = await this.supabaseService.client.auth.updateUser({
      data: {
        first_name: user.first_name,
        last_name: user.last_name,
        company: user.company,
      },
    });

    if (error) {
      return { error };
    }
    // const { error } = await this.upsertUser(user);
    // if (error) {
    // return { error };
    // }
    this.setUser({ ...user });
    return { error: null };
  }

  async changePassword(newPassword: string) {
    if (newPassword.length < 8) {
      return { error: 'Password must be at least 8 characters' };
    }

    const { error } = await this.supabaseService.client.auth.updateUser({
      password: newPassword,
    });

    if (error) {
      return { error };
    }

    return { error: null };
  }

  async fetchUserFeatures() {
    if (!this.user || !this.user.id) {
      return UserFeatures.fromList([]);
    }

    const { data: features, error } = await this.supabaseService.client
      .from('user_features_list')
      .select('*')
      .eq('user_id', this.user.id);

    if (error) {
      console.error(error);
    }

    const userFeatures = UserFeatures.fromList(features as UserFeaturesListRow[]);
    this.userFeatures$.next(userFeatures);

    return userFeatures;
  }
}
